function checkDateTime(str: string) {
    if (str.indexOf("-") != -1 || str.indexOf("/") != -1) { return true; } else { return false; }
}

function checkTime(i: any) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
let timer: any = null;
function leftTime(setdate: string, callback: any) {
    function leftTimeExeFn() {
        //获取当前时间  
        //定义变量 d,h,m,s保存倒计时的时间  
        let d = 0,
            h = 0,
            m = 0,
            s = 0,
            status = false;
        const startDate = new Date();
        const nowTime = startDate.getTime();
        //设置截止时间  
        let endTime = 0;
        let leftTimeCount = 0;
        setdate = checkDateTime(setdate) == true ? setdate.replace(/-/g, "/") : setdate;
        const endDate = new Date(setdate);
        endTime = endDate.getTime();
        leftTimeCount = endTime - nowTime;
        if (leftTimeCount > 0) {
            d = Math.floor(leftTimeCount / 1000 / 60 / 60 / 24);
            h = Math.floor(leftTimeCount / 1000 / 60 / 60 % 24);
            m = Math.floor(leftTimeCount / 1000 / 60 % 60);
            s = Math.floor(leftTimeCount / 1000 % 60);
            status = true;
        } else {
            clearInterval(timer);
            d = 0;
            h = 0;
            m = 0;
            s = 0;
            status = false;
        }
        const dataTime: any = {
            "d": checkTime(d),
            "h": checkTime(h),
            "m": checkTime(m),
            "s": checkTime(s)
        };
        dataTime.status = status;
        if (callback && typeof (callback) === "function") {
            return callback(dataTime);
        }
    }
    leftTimeExeFn()
    timer = setInterval(leftTimeExeFn, 1000);
    if (timer != "undefined" || timer != null || timer != undefined) {
        return timer;
    }
}


export const clearTime = function(){
    clearInterval(timer)
}
export default leftTime